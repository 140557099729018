import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default function Label(props) {
  const { id, label, required, className, htmlId, ...restOfProps } = props;
  return (
    <div className={className}>
      <label
        htmlFor={id}
        id={htmlId}
        className={classNames("text-xs font-semibold mb-1 mt-2 uppercase text-gray-600 block cursor-pointer", {
          relative: required,
        })}
        {...restOfProps}
      >
        {label}
      </label>
    </div>
  );
}
Label.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  htmlId: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
};

Label.defaultProps = {
  required: false,
  className: "",
  htmlId: "",
};
