import React from "react";
import PropTypes from "prop-types";
import className from "classnames";
import Skeleton from "../Skeleton";

export default function InputLoader({ fullWidth }) {
  return (
    <div
      className={className({
        "rounded text-4xl leading-tightest": true,
        "w-full": fullWidth,
        "w-1/2": !fullWidth,
      })}
    >
      <Skeleton />
    </div>
  );
}
InputLoader.propTypes = {
  fullWidth: PropTypes.bool,
};

InputLoader.defaultProps = {
  fullWidth: true,
};
