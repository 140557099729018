import React from "react";
import PropTypes from "prop-types";
import Skeleton from "../Skeleton";

export default function Textarea(props) {
  const { className, loading, ...restOfProps } = props;

  if (loading) {
    return (
      <div className="rounded w-full text-lg">
        <Skeleton count={5} />
      </div>
    );
  }
  return (
    <textarea
      className={`
    text-gray-900
    placeholder-gray-500
    bg-gray-200
    leading-tight
    md:border border-gray-300 
    w-full py-2 pt-3 px-3 
    rounded
    outline-none
    hover:bg-white hover:border-gray-400
    focus:bg-blue-100 focus:border-teal-500 focus:shadow-outline 
    ${className}`}
      {...restOfProps}
    />
  );
}

Textarea.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
};

Textarea.defaultProps = {
  className: "",
  loading: false,
};
