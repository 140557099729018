import React, { useState } from "react"
import { Helmet } from "react-helmet"
import "../style.css"
import Appbar from "../components/appbar"
import Footer from "../components/footer"
import FormGroup from "../components/FormComponents/FormGroup"
import Label from "../components/FormComponents/Label"
import Input from "../components/FormComponents/Input"
import TextArea from "../components/FormComponents/TextArea"

export default function Contact() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us | Zey Ventures</title>
      </Helmet>
      <Appbar />
      <div className="bg-secondary-light ">
        <div className="container mx-auto py-8 px-4 lg:py-16">
          <h1
            itemProp="headline"
            className="max-w-4xl mb-4 lg:mb-6 text-3xl lg:text-5xl font-serif"
          >
            Send Us a Message
          </h1>

          <div className="text-gray-800 lg:w-3/5">
            <p className="text-sm md:text-base leading-loose mb-4 lg:mb-6">
              For business inquiries, email tyler@zeyventures.com.
            </p>
          </div>
        </div>
      </div>
      <section className="container mx-auto px-4 py-8 ">
        <form
          name="contact"
          data-netlify="true"
          method="POST"
          className="w-full lg:w-1/2"
        >
          <input type="hidden" name="form-name" value="contact" />
          <FormGroup fullWidth>
            <Label id="name" label="Name" />
            <Input
              type="text"
              id="name"
              value={name}
              onChange={event => setName(event.target.value)}
              placeholder="Jane Doe"
            />
          </FormGroup>
          <FormGroup fullWidth>
            <Label id="email" label="Email" />
            <Input
              type="email"
              id="email"
              value={email}
              onChange={event => setEmail(event.target.value)}
              required
              placeholder="janedoe@gmail.com"
            />
          </FormGroup>
          <FormGroup fullWidth>
            <Label id="message" label="Message" />
            <TextArea
              rows="8"
              id="message"
              value={message}
              onChange={event => setMessage(event.target.value)}
              required
              placeholder="I'm interested in..."
            />
          </FormGroup>
          <button
            type="submit"
            className="bg-primary transition hover:bg-primary-dark text-white py-2 px-4 lg:px-8 rounded-lg"
          >
            Send
          </button>
        </form>
      </section>

      <Footer />
    </div>
  )
}
