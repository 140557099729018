import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import InputLoader from "./InputLoader";

export const inputClassName = `
text-gray-900
placeholder-gray-500
bg-gray-200
leading-tight
md:border border-gray-300 
w-full
rounded
outline-none
hover:bg-white hover:border-gray-400
focus:bg-blue-100 focus:shadow-outline
`;

// This is intentionally a class-based component.
// We are forwarding it to an npm package which uses refs and they haven't updated to useRef yet.
export default class Input extends Component {
  render() {
    const { value, className, loading, tiny, type, id, ...restOfProps } = this.props;
    if (loading) return <InputLoader />;

    const size = tiny ? "px-2 py-1" : "px-4 py-2";
    return (
      <input id={id} type={type} value={value || ""} className={classNames([inputClassName, size, className])} {...restOfProps} />
    );
  }
}

Input.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool,
  tiny: PropTypes.bool,
};

Input.defaultProps = {
  type: "text",
  className: "",
  value: "",
  loading: false,
  tiny: false,
};
