import React from "react";
import PropTypes from "prop-types";
import "./skeletonStyles.css";

export default function Skeleton({ count, duration, width, height, circle }) {
  const elements = [];

  for (let i = 0; i < count; i++) {
    let style = {
      animation: `skeleton ${String(duration)}s ease-in-out infinite`,
    };

    if (width !== null) {
      style.width = width;
    }

    if (height !== null) {
      style.height = height;
    }

    if (width !== null && height !== null && circle) {
      style.borderRadius = "50%";
    }

    elements.push(
      <span key={i} className={`skeletonStyles`} style={style}>
        &zwnj;
      </span>
    );
  }

  return <span className="flex">{elements}</span>;
}

Skeleton.propTypes = {
  count: PropTypes.number,
  duration: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  circle: PropTypes.bool,
};
Skeleton.defaultProps = {
  count: 1,
  duration: 1.2,
  width: null,
  height: null,
  circle: false,
};
